<template>
  <section>
    <data-table
      :headers="headers"
      :items="quotations"
      commit="SET_BEST_SALE"
      :viewItem="viewItem"
      :editItem="printQuotation"
      :deleteItem="editItem"
    >
      <div slot="activator" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
          <v-row justify="center" align="center">
            <v-col cols="3">
              <v-card-title class="px-0 pt-0">
                {{ $t("document") }}
              </v-card-title>
            </v-col>
            <v-col cols="9">
              <v-chip-group
                v-model="status_filter"
                mandatory
                column
                active-class="deep-purple--text text--accent-4"
              >
                <v-chip
                  v-for="(val, i) in status_list"
                  :key="i"
                  :value="val.value"
                  filter
                  outlined
                >
                  {{ $t(val.text) }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 text-right">
          <TextSelect
            :dense="true"
            :placeholder="false"
            :items="documents"
            v-model="document"
            itemText="text"
            itemValue="id"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 text-right">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2 text-right">
          <v-btn
            min-width="auto"
            depressed
            dark
            color="primary"
            @click="newItem"
          >
            <v-icon left small>fal fa-plus-circle</v-icon>
            {{ $t("add_document") }}
          </v-btn>
        </div>
      </div>
    </data-table>
    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      width="750"
      title="description"
      @submit="submitForm"
    >
      <div slot="activator">
        <AppDetail :item="form" @btnAction="btnAction" />
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="update ? 'update_document' : 'add_document'"
      @submit="submitForm"
      :showSubmitIcon="false"
      submitText="choose_product"
    >
      <div slot="activator">
        <v-tabs
          v-model="form.document"
          color="primary"
          grow
          background-color="transparent"
        >
          <v-tab
            v-for="(val, i) in documents"
            :key="i"
            class="text-capitalize"
            :tab-value="val"
          >
            {{ val.text }}
          </v-tab>
        </v-tabs>
        <v-divider class="mt-0" />

        <v-row align="center">
          <v-col cols="11">
            <TextSelect
              label="choose_customer"
              v-model="form.customer_id"
              itemValue="id"
              text="first_name"
              :items="customers"
            />
          </v-col>
          <v-col cols="1">
            <v-btn icon color="primary" class="mt-4" to="/manage-customers">
              <v-icon>fal fa-plus-circle</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <CheckBox v-model="form.include_tax" label="tax_include" />
        <CheckBox
          v-if="form.document.value === 'quotation'"
          v-model="showExpiredDate"
          label="show_expired"
        />
        <template v-else>
          <div class="pt-2">{{ $t("choose_currency") }}</div>
          <v-chip-group
            v-model="form.currency"
            mandatory
            column
            active-class="deep-purple--text text--accent-4"
          >
            <v-chip
              v-for="(val, i) in currencies"
              :key="i"
              :value="val.name"
              filter
              outlined
            >
              {{ val.name }}
            </v-chip>
          </v-chip-group>
        </template>
        <DatePicker
          v-if="showExpiredDate"
          label="expired_date"
          v-model="form.expiry_date"
        />
        <TextArea
          v-model="form.note"
          label="noted"
          outlined
          multi-line
          :rows="3"
        />
      </div>
    </app-custom-dialog>
    <!-- AppLoading -->
    <app-loading :overlay="overlay" />
    <app-print v-show="print" :item="form" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { quatationHead } from "@/utils/head";
import TTextField from "@/components/global/TextField";
import CheckBox from "@/components/global/CheckBox";
import { success } from "@/controllers/alert";
import TextSelect from "@/components/global/TextSelect";
import { mainStore } from "@/stores/modules/cart";
import AppDetail from "@/components/quotation/detail";
import AppPrint from "@/components/quotation/print";
import TextArea from "@/components/global/TextArea";

export default {
  data: () => ({
    print: false,
    update: false,
    overlay: false,
    showExpiredDate: false,
    search: "",
    dialogAdd: false,
    dialog: false,
    defineStore: mainStore(),
    document: {
      id: 1,
      text: "quotation",
      value: "quotation",
    },
    form: {
      document: {
        id: 1,
        text: "quotation",
        value: "quotation",
      },
      currency: "lak",
      customer_id: null,
      customer: {},
      store: {
        thb: 0,
        usd: 0,
      },
    },
    status_filter: "all",
    status_list: [
      { text: "all", value: "all" },
      { text: "pending", value: "pending" },
      { text: "approved", value: "accepted" },
      { text: "rejected", value: "rejected" },
    ],
  }),
  async created() {
    await this.fetchItem();
  },
  components: {
    DataTable,
    TTextField,
    AppDetail,
    TextSelect,
    CheckBox,
    AppPrint,
    TextArea,
  },

  computed: {
    ...mapGetters({
      quotations: "index/quotations",
      customers: "index/customers",
      currencies: "index/currencies",
      tax: "auth/tax",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    headers() {
      return quatationHead(this);
    },
    documents() {
      return [
        {
          id: 1,
          text: this.$t("quotation"),
          value: "quotation",
        },
        {
          id: 2,
          text: this.$t("invoice"),
          value: "invoice",
        },
      ];
    },
  },

  watch: {
    status_filter() {
      this.fetchItem();
    },
    document(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchItem();
      }
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    ...mapMutations({
      SET_NEW_QUOTATION: "index/SET_NEW_QUOTATION",
    }),
    async fetchItem() {
      const params = `q=${this.search}&status=${this.status_filter}&document=${this.document.value}`;
      const body = {
        that: this,
        url: `quotation?${params}`,
        commit: "SET_QUOTATIONS",
      };
      await this.fetch(body);
    },
    async fetchCustomer() {
      const body = {
        that: this,
        url: "customers",
        commit: "SET_CUSTOMER",
      };
      await this.fetch(body);
    },
    async setDefault() {
      this.form = {
        document: {
          id: 1,
          text: "quotation",
          value: "quotation",
        },
        currency: "lak",
        customer_id: null,
        customer: {},
        store: {
          thb: 0,
          usd: 0,
        },
      };
    },
    async newItem() {
      this.setDefault();
      await this.fetchItem();
      await this.fetchCustomer();
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    async printQuotation({ id }) {
      try {
        const { data } = await this.$http.get(`quotation_detail/${id}`);
        if (data.status) {
          this.form = data.data;
          setTimeout(() => {
            this.$htmlToPaper("quotationPrint");
          }, 500);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async editItem({ id }) {
      try {
        const { data } = await this.$http.get(`quotation_detail/${id}`);
        if (data.status) {
          await this.fetchCustomer();
          if (data.data.expiry_date) {
            this.showExpiredDate = true;
          }
          this.form = {
            update: true,
            id: data.data.id,
            customer_id: data.data.customer,
            expiry_date: data.data.expiry_date,
            note: data.data.noted,
            include_tax: data.data.include_tax ? true : false,
            customer: { id: data.data.customer },
            store: data.data.store,
          };
          this.dialogAdd = !this.dialogAdd;
          this.update = true;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async viewItem({ id }) {
      try {
        const { data } = await this.$http.get(`quotation_detail/${id}`);
        if (data.status) {
          this.form = data.data;
          this.dialog = !this.dialog;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async submitForm() {
      const body = {
        id: this.form.id,
        note: this.form.note,
        update: this.update,
        customer_id: this.form.customer_id,
        include_tax: this.form.include_tax,
        expiry_date: this.form.expiry_date,
        currency: this.form.currency,
        document: this.form.document.value,
      };
      this.dialogAdd = !this.dialogAdd;
      this.SET_NEW_QUOTATION(body);
      this.defineStore.deleteCart();
      await this.getQuotationCart();
      // sale
      this.$router.push({ name: "sale" });
    },
    async btnAction(val) {
      try {
        const body = {
          id: this.form.id,
          status: val,
        };
        const { data } = await this.$http.post("quotation_status", body);
        if (data.status) {
          this.dialog = !this.dialog;
          await success(this, data.message);
          await this.fetchItem();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getQuotationCart() {
      const id = this.form.id;
      const { data } = await this.$http.get(`quotation_cart/${id}`);
      if (data.status) {
        const cart = data.data.map((e) => ({
          id: e.product_id.id,
          qty: e.quantity,
          discount: 0,
          price: e.product_id.price_sale * e.quantity,
          item: e.product_id,
        }));
        this.defineStore.setCart(cart);
      }
    },
  },
};
</script>

<style></style>
