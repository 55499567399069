<template>
  <section id="quotationPrint" v-if="Object.keys(item).length > 0">
    <div class="container quotationPrint">
      <div class="header">
        <div class="logo">
          <img v-if="Img" width="70" :src="Img" alt="logo" class="logo" />
        </div>
        <h1 class="quotation-title">{{ documents }}</h1>
      </div>

      <div class="address-block">
        <div class="address">
          <h3>{{ document_by }}</h3>
          <p>{{ user.stores.name }}</p>
          <p>
            <v-icon small color="#000000" left> fal fa-map-marker-alt </v-icon>
            {{ user.stores.address }}
          </p>
          <p>
            <v-icon small color="#000000" left> fal fa-phone </v-icon>
            {{ user.stores.phone_no }}
          </p>
          <p>
            <v-icon small color="#000000" left> fal fa-envelope </v-icon>
            {{ user.email }}
          </p>
        </div>
        <div class="address">
          <h3>{{ document_to }}</h3>
          <p>
            <v-icon small color="#000000" left> fal fa-user </v-icon>
            {{ item.customer.first_name }}
          </p>
          <p>
            <v-icon small color="#000000" left> fal fa-map-marker-alt </v-icon>
            {{ item.customer.address }}
          </p>
          <p>
            <v-icon small color="#000000" left> fal fa-phone </v-icon>
            {{ item.customer.phone_no }}
          </p>
        </div>
        <div class="address">
          <h3>{{ documents_info }}</h3>
          <p>
            <v-icon small color="#000000" left> fal fa-file-alt </v-icon>
            {{ document_no }} {{ item.quotation_no }}
          </p>
          <p>
            <v-icon small color="#000000" left> fal fa-calendar-alt </v-icon>
            {{ $t("issue_date") }} {{ item.created_at | created_at }}
          </p>
          <p v-if="item.document === 'quotation'">
            <v-icon small color="#000000" left> fal fa-calendar-alt </v-icon>
            {{ $t("expiry_date") }} {{ item.expiry_date | created_at }}
          </p>
        </div>
        <!-- <div class="address">
          <h3>{{ $t("exchange_rate") }}</h3>
          <p>LAKTHB {{ item.store.thb | NumberFormat }}</p>
          <p>LAKUSD {{ item.store.usd | NumberFormat }}</p>
        </div> -->
      </div>

      <table>
        <tr>
          <th>{{ $t("no") }}</th>
          <th>{{ $t("item") }}</th>
          <th>{{ $t("price") }}</th>
          <th>{{ $t("quantity") }}</th>
          <th>{{ $t("total") }}</th>
        </tr>
        <tr v-for="(item, index) in item.quotation_details" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.product.name }}</td>
          <td>{{ item.unit_price | NumberFormat }}</td>
          <td>{{ item.quantity | NumberFormat }}</td>
          <td>{{ item.total_price | NumberFormat }}</td>
        </tr>
      </table>

      <div class="total">
        <p>{{ $t("amount_paid") }} : {{ item.total_amount | NumberFormat }}</p>
        <p>VAT {{ tax }} : {{ item.tax_amount | NumberFormat }}</p>
        <template v-if="item.document === 'quotation'">
          <p>{{ $t("total") }} THB : {{ thRate }}</p>
          <p>{{ $t("total") }} USD : {{ usRate }}</p>
        </template>
        <p>
          <strong
            >{{ $t("grand_total") }} :
            {{ item.grand_total | NumberFormat }}
          </strong>
          <span v-if="item.document === 'invoice'">
            {{ item.currency }}
          </span>
        </p>
      </div>

      <div class="notes">
        <h3>{{ $t("additional_note") }}</h3>
        <div v-html="item.noted" />
      </div>

      <div class="signature">
        <p>{{ $t("authorized_signature") }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { myMixin } from "@/mixin/main";

export default {
  mixins: [myMixin],
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    Img() {
      if (this.user && this.user.stores && this.user.stores.logo) {
        // check logo is contain default string or not
        if (!this.user.stores.logo.includes("default")) {
          return Help.img(this.user.stores.logo);
        }
      }
    },
    thRate() {
      if (this.item && this.item.store && this.item.store.thb) {
        const thb = this.item.store.thb;
        const total = this.item.grand_total;
        return this.$options.filters.NumberFormat(total / thb);
      }
      return 0;
    },
    usRate() {
      if (this.item && this.item.store && this.item.store.usd) {
        const usd = this.item.store.usd;
        const total = this.item.grand_total;
        return this.$options.filters.NumberFormat(total / usd);
      }
      return 0;
    },
    documents() {
      if (this.item && this.item.document) {
        const doc = this.item.document;
        // if doc === quotation
        if (doc === "quotation") {
          return this.$t("quotation_title");
        } else {
          return this.$t("invoice");
        }
      }
    },
    document_by() {
      if (this.item && this.item.document_by) {
        const doc = this.item.document_by;
        // if doc === quotation
        if (doc === "quotation") {
          return this.$t("quotation_by");
        } else {
          return this.$t("invoice_by");
        }
      }
    },
    document_to() {
      if (this.item && this.item.document_to) {
        const doc = this.item.document_to;
        // if doc === quotation
        if (doc === "quotation") {
          return this.$t("quotation_to");
        } else {
          return this.$t("invoice_to");
        }
      }
    },
    documents_info() {
      if (this.item && this.item.document_info) {
        const doc = this.item.document_info;
        // if doc === quotation
        if (doc === "quotation") {
          return this.$t("quotations_info");
        } else {
          return this.$t("invoice_info");
        }
      }
    },
    document_no() {
      if (this.item && this.item.document_no) {
        const doc = this.item.document_no;
        // if doc === quotation
        if (doc === "quotation") {
          return this.$t("quotation_no");
        } else {
          return this.$t("invoice_no");
        }
      }
    },
  },
};
</script>
