<template>
  <section>
    <data-table
      :headers="headers"
      :items="trash"
      commit="SET_TRASH"
      :viewItem="viewItem"
      :editItem="restore"
      :deleteItem="forceDelete"
    >
      <div slot="activator" class="row justify-content-between">
        <v-col cols="2">
          <v-card-title class="px-0 pt-0"> ກະຕ່າຂີ້ເຫຍື່ອ </v-card-title>
        </v-col>
        <v-col cols="4">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </v-col>
      </div>
    </data-table>
    <app-custom-dialog v-model="dialog" :footer="false" title="description">
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>

    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { trashProductHead } from "@/utils/head";

import AppDetail from "@/components/products/details";
import { myMixin } from "@/mixin/main";
import { success, confirm } from "@/controllers/alert";

export default {
  mixins: [myMixin],
  data: () => ({
    overlay: false,
    search: "",
    update: false,
    dialogAdd: false,
    dialog: false,
    headers: trashProductHead(),
    form: {},
  }),
  async created() {
    await this.fetchItem();
  },
  components: { DataTable, AppDetail },
  computed: {
    ...mapGetters({
      trash: "index/trash",
    }),
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const body = {
        that: this,
        url: `product_trash?q=${this.search}`,
        commit: "SET_TRASH",
      };
      await this.fetch(body);
    },
    async restore({ id }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "ຕ້ອງການກູ້ຄືນ!",
      });
      if (isConfirmed) {
        const { data } = await this.$http.get(`product_restore/${id}`);
        if (data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      }
    },
    async forceDelete({ id }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.delete(`product_force_delete/${id}`);
        if (data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
  },
};
</script>

<style></style>
