const pubnubConfig = () => {
  const data = {
    subscribeKey: "sub-c-7b1539ae-9cec-471d-bdc7-11cbdd11cbdd",
    uuid: "018d5076-6201-4b81-977d-e1a183362d48",
  };
  return data;
};

const appBarHead = () => {
  return [
    {
      icon: "fal fa-home",
      text: "home",
      to: "/",
      items: [],
    },
    {
      icon: "fal fa-home",
      text: "business_type",
      to: "/",
      items: [
        {
          text: "grocery",
          to: "/grocery",
        },
        {
          text: "cafe",
          to: "/cafe",
        },
        {
          text: "restaurant",
          to: "/restaurant",
        },
        {
          text: "bar",
          to: "/bar",
        },
        {
          text: "retail",
          to: "/retail",
        },
        {
          text: "clothing",
          to: "/clothing",
        },
      ],
    },
    {
      text: "products",
      icon: "fal fa-box",
      to: "/products",
      items: [
        {
          text: "kitchen",
          to: "/products/kitchen-display",
        },
        {
          text: "smart_order",
          to: "/products/smart-order",
        },
        {
          text: "table_management",
          to: "/products/table-management",
        },
        {
          text: "employee_management",
          to: "/products/employee-management",
        },
        {
          text: "inventory_management",
          to: "/products/inventory-management",
        },
        {
          text: "customer_management",
          to: "/products/customer-management",
        },
      ],
    },
    {
      icon: "fal fa-play",
      text: "tutorials",
      to: "/tutorials",
      items: [],
    },
    {
      icon: "fal fa-sign-in-alt",
      text: "login",
      to: "/login",
      items: [],
    },
  ];
};

const TableStatus = (prop) => {
  const data = [
    {
      text: prop.$t("all"),
      value: "all",
    },
    {
      text: prop.$t("pending"),
      value: "pending",
    },
    {
      text: prop.$t("cooking"),
      value: "cooking",
    },
    {
      text: prop.$t("ready"),
      value: "ready",
    },
    {
      text: prop.$t("cancel"),
      value: "cancel",
    },
    {
      text: prop.$t("delivered"),
      value: "delivered",
    },
    {
      text: prop.$t("paid"),
      value: "paid",
    },
  ];
  return data;
};

const OrderStatus = (prop) => {
  const data = [
    {
      text: prop.$t("all"),
      value: null,
    },
    {
      text: prop.$t("pending"),
      value: "pending",
    },
    {
      text: prop.$t("completed"),
      value: "completed",
    },
    {
      text: prop.$t("ready"),
      value: "ready",
    },
    {
      text: prop.$t("decline"),
      value: "decline",
    },
    {
      text: prop.$t("cancel"),
      value: "cancel",
    },
  ];
  return data;
};

const tableStatusList = () => {
  const status_list = [
    { text: "all", value: "" },
    { text: "available", value: "available" },
    { text: "busy", value: "busy" },
    { text: "reserved", value: "reserved" },
  ];
  return status_list;
};
const productSort = () => {
  const status_list = [
    { text: "new_to_old", value: "desc" },

    { text: "old_to_new", value: "asc" },
  ];
  return status_list;
};

const ActionList = () => {
  const actionList = [
    {
      text: "cancel",
      icon: "fal fa-times-circle",
      value: "cancel",
    },
    {
      text: "cooking",
      icon: "fal fa-utensils-alt",
      value: "cooking",
    },
    {
      text: "delivered",
      icon: "fal fa-truck-loading",
      value: "delivered",
    },
  ];
  return actionList;
};

const billPOS = () => {
  const rawData = localStorage.getItem("user");
  const user = JSON.parse(rawData);
  const first_name = user.first_name;
  const phone_no = user.phone_no;
  const store_name = user?.stores?.name ?? "Possmart";
  const store_phone_no = user?.stores?.phone_no ?? "2054444151";
  const store_address = user?.stores?.address ?? "Vientiane";
  const store_logo = user?.stores?.logo ?? "https://i.imgur.com/7bI1k1b.png";
  const branch_name = user?.branch_id?.name ?? "Vientiane";
  const branch_phone_no = user?.branch_id?.phone_no ?? "2028492947";
  const data = {
    code: "EK3OTT0P0815",
    total_price_buy: 1029600,
    total_price_sale: 1148400,
    tax_amount: 114840,
    total_profit: 118800,
    total_price: 1263240,
    total_paid: 1263240,
    total_overdue: 0,
    total_discount: 0,
    status: "done",
    customer_id: {
      id: 296,
      first_name: "Vongkeo",
      point: 151,
      phone_no: "2054444151",
      address: "Vientiane",
    },
    type: "cash",
    created_at: "2024-06-29T16:46:57.000000Z",
    store_id: {
      name: store_name,
      logo: store_logo,
      phone_no: store_phone_no,
      address: store_address,
      qr: null,
      thb: 650,
      usd: 22100,
      tax: 10,
      tax_include: 1,
    },
    user_id: {
      first_name: first_name,
      phone_no: phone_no,
    },
    branch_id: {
      name: branch_name,
      phone_no: branch_phone_no,
    },
    history_count: 1,
    history: [
      {
        id: 8961,
        qty: 1,
        tax_amount: 11000,
        price_sale: 110000,
        discount: 0,
        total_price: 110000,
        ticket_id: 2041,
        product_id: {
          id: 1749,
          name: "T-shirt",
        },
        status: "done",
      },
      {
        id: 528,
        qty: 1,
        tax_amount: 110000,
        price_sale: 1100000,
        discount: 0,
        total_price: 1100000,
        ticket_id: 219,
        product_id: {
          id: 5,
          name: "Kaspersky",
        },
        status: "done",
      },
      {
        id: 529,
        qty: 1,
        tax_amount: 5324,
        price_sale: 53240,
        discount: 0,
        total_price: 53240,
        ticket_id: 219,
        product_id: {
          id: 1,
          name: "USB Flash Drive",
        },
        status: "done",
      },
    ],
  };
  return data;
};
const billRestaurant = () => {
  return {};
};

const STORE_TYPE = () => {
  return [
    {
      text: "pos",
      value: "POS",
    },
    {
      text: "restaurant",
      value: "RESTAURANT",
    },
    {
      text: "pharmacy",
      value: "PHARMACY",
    },
    {
      text: "coffee",
      value: "COFFEE",
    },
  ];
};

const kitchenList = (prop) => {
  return [
    {
      text: prop.$t("cancel"),
      icon: "fal fa-times-circle",
      value: "cancel",
    },
    {
      text: prop.$t("ready"),
      icon: "fal fa-truck-loading",
      value: "ready",
    },
    {
      text: prop.$t("print"),
      icon: "fal fa-print",
      value: "print",
    },
  ];
};

const kitchenItem = (prop) => {
  return [
    {
      text: prop.$t("cancel"),
      icon: "fal fa-times-circle",
      value: "cancel",
    },
    {
      text: prop.$t("ready"),
      icon: "fal fa-truck-loading",
      value: "ready",
    },
  ];
};

const kitchenStatusAction = (prop) => {
  return [
    {
      text: prop.$t("cooking"),
      value: "cooking",
    },
    {
      text: prop.$t("cancel"),
      value: "cancel",
    },
    {
      text: prop.$t("ready"),
      value: "ready",
    },
    {
      text: prop.$t("delivered"),
      value: "delivered",
    },
  ];
};
const ticketStatus = (prop) => {
  return [
    {
      text: prop.$t("all"),
      value: "all",
    },
    {
      text: prop.$t("done"),
      value: "done",
    },
    {
      text: prop.$t("overdue"),
      value: "overdue",
    },
    {
      text: prop.$t("cancel"),
      value: "cancel",
    },
  ];
};

const purchaseStatus = (prop) => {
  return [
    {
      text: prop.$t("all"),
      value: "all",
    },
    {
      text: prop.$t("pending"),
      value: "pending",
    },
    {
      text: prop.$t("approved"),
      value: "approved",
    },
    {
      text: prop.$t("rejected"),
      value: "rejected",
    },
  ];
};

const listSettings = () => {
  return [
    {
      text: "general",
      icon: "user-circle",
      value: 0,
      color: "#4A90E2",
    },
    {
      text: "store",
      icon: "store",
      value: 1,
      color: "#50C878",
    },
    {
      text: "user",
      icon: "edit",
      value: 2,
      color: "#FFA500",
    },
    {
      text: "change_password",
      icon: "key",
      value: 3,
      color: "#FFD700",
    },
    {
      text: "package",
      icon: "box-open",
      value: 4,
      color: "#8A2BE2",
    },
    {
      text: "kitchen",
      icon: "warehouse",
      value: 5,
      color: "#FF6347",
    },
    {
      text: "customer_screen",
      icon: "tv",
      value: 6,
      color: "#20B2AA",
    },
    {
      text: "receipt",
      icon: "receipt",
      value: 7,
      color: "#F08080",
    },
    {
      text: "dark_mode",
      icon: "moon",
      value: 8,
      color: "#483D8B",
    },
    {
      text: "logout",
      icon: "sign-out-alt",
      value: 9,
      color: "#DC143C",
    },
  ];
};

const refundList = (prop) => {
  return [
    {
      text: prop.$t("not_match"),
      value: "not_match",
    },
    {
      text: prop.$t("change_product"),
      value: "change_product",
    },
    {
      text: prop.$t("problem"),
      value: "problem",
    },
    {
      text: prop.$t("other"),
      value: "other",
    },
  ];
};

const servicesList = (prop) => {
  return [
    {
      text: prop.$t("online"),
      icon: "fal fa-shopping-bag",
      desc: prop.$t("online_desc"),
      color: "#4CAF50 ",
    },
    {
      text: prop.$t("restaurant"),
      icon: "fal fa-utensils",
      desc: prop.$t("restaurant_desc"),
      color: "#D32F2F",
    },
    {
      text: prop.$t("coffee"),
      icon: "fal fa-coffee",
      desc: prop.$t("coffee_desc"),
      color: "#795548",
    },
    {
      text: prop.$t("pharmacy"),
      icon: "fal fa-first-aid",
      desc: prop.$t("pharmacy_desc"),
      color: "#2196F3",
    },
    {
      text: prop.$t("computer"),
      icon: "fal fa-laptop",
      desc: prop.$t("computer_desc"),
      color: "#FF5722",
    },
    {
      text: prop.$t("retail"),
      icon: "fal fa-shopping-cart",
      desc: prop.$t("retail_desc"),
      color: "#FFC107",
    },
    {
      text: prop.$t("clothing"),
      icon: "fal fa-tshirt",
      desc: prop.$t("clothing_desc"),
      color: "#9C27B0",
    },
  ];
};

export {
  pubnubConfig,
  billPOS,
  STORE_TYPE,
  TableStatus,
  tableStatusList,
  ActionList,
  billRestaurant,
  kitchenList,
  kitchenItem,
  kitchenStatusAction,
  productSort,
  ticketStatus,
  purchaseStatus,
  appBarHead,
  listSettings,
  OrderStatus,
  refundList,
  servicesList,
};
