<template>
  <section>
    <v-card class="rounded-lg">
      <v-card-title>
        <v-icon left small> fal fa-user-circle </v-icon
        >{{ $t("general") }}</v-card-title
      >
      <v-card-text>
        <v-row align="center">
          <v-col xs="12" sm="12" md="4" lg="4" xl="4" class="text-center">
            <v-badge
              bordered
              bottom
              color="primary"
              offset-x="50"
              offset-y="22"
              icon="fal fa-camera"
              overlap
            >
              <v-avatar
                color="#f5f5f5"
                size="158"
                v-if="MyRole('change_logo_store')"
              >
                <v-avatar-uploader
                  :url="urlLogo"
                  :clickable="true"
                  field="logo"
                  :rename="rename"
                  @failed="failed"
                  :request="requestLogo"
                />
              </v-avatar>
              <v-avatar size="150" v-else>
                <v-img :src="urlLogo"></v-img>
              </v-avatar> </v-badge
          ></v-col>
          <v-divider vertical />

          <v-col xs="12" sm="12" md="8" lg="8" xl="8">
            <v-card-text>
              <AppTitle title="store_name" :value="store?.name" />
              <AppTitle title="business_type" :value="store?.store_type_code" />
              <AppTitle title="store_phone" :value="store?.phone_no" />
              <AppTitle title="store_address" :value="store?.address" />
              <AppTitle title="store_expire" :value="store?.end_date" />
              <AppTitle title="product_count" :value="store?.product_count" />
              <AppTitle
                v-if="store.tax_include"
                title="tax"
                :value="store?.tax"
              />
              <AppTitle
                title="description"
                :value="store?.desc"
                :divider="false"
              />
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row class="mt-3">
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-card-title>
            <v-icon left small> fal fa-globe-asia </v-icon>
            {{ $t("change_language") }}
          </v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item
                v-for="(locale, index) in locales"
                :key="index"
                @click="changeLocale(locale.code)"
              >
                <v-list-item-avatar size="30">
                  <v-img :src="locale.flag" />
                </v-list-item-avatar>

                <v-list-item-title
                  :class="locale.code === $i18n.locale ? 'primary--text' : ''"
                  >{{ locale.name }}</v-list-item-title
                >

                <v-list-item-icon>
                  <v-icon v-if="locale.code === $i18n.locale" color="primary">
                    fal fa-check
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import VAvatarUploader from "@/components/VAvatarUploader";
import AppTitle from "@/components/settings/app_title";
import { setUser } from "@/controllers/auth";
import { myMixin } from "@/mixin/main";
import { success } from "@/controllers/alert";
import { mapGetters } from "vuex";

export default {
  props: ["store"],
  components: {
    VAvatarUploader,
    AppTitle,
  },
  data() {
    return {};
  },
  mixins: [myMixin],
  computed: {
    ...mapGetters({
      locales: "auth/locales",
    }),
    urlLogo() {
      if (this.user && this.user.stores && this.user.stores.logo) {
        const baseURL = process.env.VUE_APP_BASE_URL;
        return `${baseURL}/uploads/${this.user.stores.logo}`;
      } else {
        return "/images/pos.png";
      }
    },
  },
  methods: {
    async requestLogo(form, config) {
      const { data } = await this.$http.post("change_store_logo", form, config);
      if (data.status) {
        await setUser(this);
        await success(this, data.message);
      }
    },
    rename(file) {
      const ext = file.name.split(".")[1];
      const name = `1231232.${ext}`;
      return name;
    },
    failed(error) {
      console.log("error", error);
    },
    calDay(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
    changeLocale(code) {
      localStorage.setItem("locale", code);
      this.$i18n.locale = code;
    },
  },
};
</script>

<style></style>
